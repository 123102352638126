export const SalesNav = [
  {
    icon: "pe-7s-cash",
    label: "Vendas",
    content: [
      {
        label: "Cadastros",
        content: [
          {
            label: "Clientes",
            to: '/#/vendas/clientes'
          },
          {
            label: "Produtos",
            to: '/#/vendas/produtos'
          },
          {
            label: "Serviços",
            to: '/#/vendas/servicos'
          },
          {
            label: "Transportadoras",
            to: '/#/vendas/transportadoras'
          },
          {
            label: "Vendedores",
            to: '/#/vendas/vendedores'
          },
          {
            label: "Grupo de Produtos",
            to: '/#/vendas/grupo-produtos'
          }
        ]
      },
      {
        label: "Controle",
        content: [
          {
            label: "Contratos",
            to: "/#/vendas/contratos"
          },
          {
            label: "Estoque",
            to: '/#/vendas/estoque'
          },
          {
            label: "Frente de Caixa",
            to: "/#/vendas/pdv"
          },
          {
            label: "Manifesto de Documentos Fiscais (MDFe)",
            to: "/#/vendas/manifestos-eletronicos"
          },
          {
            label: "Nota Fiscal Consumidor (NFCe)",
            to: "/#/vendas/notas-fiscais-consumidor"
          },
          {
            label: "Nota Fiscal Produto (NFe)",
            to: "/#/vendas/notas-fiscais"
          },
          {
            label: "Vendas e Orçamentos",
            to: "/#/vendas"
          },
        ],
      }
    ]
  }
];
export const ServicesNav = [
  {
    icon: "pe-7s-cash",
    label: "Serviços",
    content: [
      {
        label: "Cadastros",
        content: [
          {
            label: "Serviços",
            to: '/#/servicos'
          },
          {
            label: "Grupo de Produtos",
            to: '/#/vendas/grupo-produtos'
          }
        ]
      },
      {
        label: "Controle",
        content: [
          {
            label: "Nota Fiscal Serviço (NFSe)",
            to: "/#/servicos/notas-fiscais-servico"
          },
          {
            label: "Ordem de Serviço e Orçamentos",
            to: "/#/servicos/ordem-servico"
          },
        ],
      }
    ]
  }
];
export const PurchasesNav = [
  {
    icon: "pe-7s-shopbag",
    label: "Compras",
    content: [
      {
        label: "Cadastros",
        content: [
          {
            label: "Fornecedores",
            to: '/#/compras/fornecedores'
          },
          {
            label: "Impostos",
            to: '/#/compras/impostos'
          },
          {
            label: "Produtos",
            to: '/#/compras/produtos'
          },
          {
            label: "Grupo de Produtos",
            to: '/#/compras/grupo-produtos'
          }
        ]
      },
      {
        label: "Controle",
        content: [
          {
            label: "Compras",
            to: '/#/compras'
          },
          {
            label: "Contratos",
            to: "/#/compras/contratos"
          },
          {
            label: "Estoque",
            to: '/#/compras/estoque'
          },
          {
            label: "Notas de Compras",
            to: '/#/compras/notas-fiscais'
          }
        ]
      }
    ]
  }
];
export const FinancialNav = [
  {
    icon: "pe-7s-piggy",
    label: "Financeiro",
    content: [
      {
        label: "Cadastros",
        content: [
          {
            label: "Categorias",
            to: '/#/financeiro/categorias'
          },
          {
            label: "Contas",
            to: '/#/financeiro/contas'
          },
          {
            label: "Contas a Receber",
            to: '/#/financeiro/contas-receber'
          },
          {
            label: "Contas a Pagar",
            to: '/#/financeiro/contas-pagar'
          },
          {
            label: "Centro de custos",
            to: '/#/financeiro/centro-custo'
          },
          {
            label: "Projetos",
            to: '/#/financeiro/projetos'
          },
          {
            label: "Naturezas de Operação",
            to: '/#/financeiro/natureza-operacao'
          },
          {
            label: "WFPay",
            to: '/#/financeiro/nfconta'
          },
        ]
      },
      {
        label: "Controle",
        content: [
          {
            label: "Conciliação Bancária",
            to: '/#/financeiro/conciliacao'
          },
          {
            label: "Extrato",
            to: '/#/financeiro/extrato'
          },
          {
            label: "Remessas",
            to: '/#/financeiro/remessas'
          },
          {
            label: "Retornos",
            to: '/#/financeiro/retornos'
          },
          {
            label: "Histórico Movimentações",
            to: '/#/financeiro/historico'
          },
        ]
      }
    ]
  }
];
export const ReportsNav = [
  {
    icon: "pe-7s-graph1",
    label: "Relatórios",
    content: [

      {
        label: "Financeiro",
        content: [
          {
            label: "Fluxo Diário",
            to: '/#/relatorios/fluxo-diario'
          },
          {
            label: "Fluxo Mensal",
            to: '/#/relatorios/fluxo-mensal'
          },
          {
            label: "Análise Recebimentos",
            to: '/#/relatorios/analise-recebimentos'
          },
          {
            label: "Análise Pagamentos",
            to: '/#/relatorios/analise-pagamentos'
          },
          {
            label: "Posição Contas",
            to: '/#/relatorios/posicao-contas'
          },
          {
            label: "DRE",
            to: '/#/relatorios/dre'
          },
          {
            label: "Dashboard DRE",
            to: '/#/relatorios/dashboard-dre'
          },
          {
            label: "Dashboard",
            to: '/#/relatorios/dashboard-resumo'
          },
        ]
      },
      {
        label: "Vendas",
        content: [
          {
            label: "Vendas Cliente",
            to: '/#/relatorios/vendas-cliente'
          },
          {
            label: "Vendas Vendedor",
            to: '/#/relatorios/vendas-vendedor'
          },
          {
            label: "Comissão Vendedor",
            to: '/#/relatorios/comissao-vendedor'
          },
          {
            label: "Mais Vendidos",
            to: '/#/relatorios/mais-vendidos'
          },
        ]
      },
      {
        label: "Geral",
        content: [
          {
            label: "Produtos Cliente",
            to: '/#/relatorios/produtos-cliente'
          },
          {
            label: "Clientes",
            to: '/#/relatorios/clientes'
          },
          {
            label: "Giro Estoque",
            to: '/#/relatorios/giro-estoque'
          },
        ]
      },
      {
        label: "",
        content: []
      }
    ]
  }
];
