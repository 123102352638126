const map = {
  vendas: {
    0: 'Acessou tela de vendas (Vendas > Controle > Vendas e Orçamentos)',
    clientes: 'Acessou a tela de clientes (Vendas > Cadastros > Clientes)',
    produtos: 'Acessou a tela de produtos (Vendas > Cadastros > Produtos)',
    transportadoras: 'Acessou a tela de transportadoras (Vendas > Cadastros > Transportadoras)',
    vendedores: 'Acessou a tela de vendedores (Vendas > Cadastros > Vendedores)',
    contratos: 'Acessou a tela de contratos (Vendas > Controle > Contratos)',
    estoque: 'Acessou a tela de estoque (Vendas > Controle > Estoque)',
    pdv: 'Acessou a tela de frente de caixa (Vendas > Controle > Frente de Caixa)',
    'notas-fiscais': 'Acessou a tela de NF-e (Vendas > Controle > Nota Fiscal Produto (NFe))',
    'inutilizar-notas': 'Acessou a tela de inutilizar NF-e (Vendas > Controle > Nota Fiscal Produto (NFe) > Inutilizar)',
    'notas-fiscais-consumidor': 'Acessou a tela de NFC-e (Vendas > Controle > Nota Fiscal Consumidor (NFCe))',
    'manifestos-eletronicos': 'Acessou a tela de MDF-e (Vendas > Controle > Manifesto de Documentos Fiscais (MDFe))',
    inventario: 'Acessou a tela de inventário (Vendas > Controle > Estoque > Inventário)'
  },
  servicos: {
    0: 'Acessou a tela de serviços (Serviços > Cadastros > Serviços)',
    'ordem-servico': 'Acessou a tela de ordem de serviço (Serviços > Controle > Ordem de Serviço e Orçamentos)',
    'notas-fiscais-servico': 'Acessou a tela de NFS-e (Serviços > Controle > Nota Fiscal Serviço (NFSe))',
  },
  compras: {
    0: 'Acessou tela de compras (Compras > Controle > Compras)',
    fornecedores: 'Acessou a tela de fornecedores (Compras > Cadastros > Fornecedores)',
    impostos: 'Acessou a tela de impostos (Compras > Cadastros > Impostos)',
    produtos: 'Acessou a tela de produtos (Compras > Cadastros > Produtos)',
    vendedores: 'Acessou a tela de vendedores (Compras > Cadastros > Vendedores)',
    contratos: 'Acessou a tela de contratos (Compras > Controle > Contratos)',
    estoque: 'Acessou a tela de estoque (Compras > Controle > Estoque)',
    inventario: 'Acessou a tela de inventário (Compras > Controle > Estoque > Inventário)',
    'notas-fiscais': 'Acessou a tela de notas fiscais de compras (Compras > Controle > Notas de Compras)'
  },
  financeiro: {
    categorias: 'Acessou a tela de categorias (Financeiro > Cadastros > Categorias)',
    contas: 'Acessou a tela de contas bancárias (Financeiro > Cadastros > Contas)',
    'contas-receber': 'Acessou a tela de contas a receber (Financeiro > Cadastros > Contas a Receber)',
    'contas-pagar': 'Acessou a tela de contas a pagar (Financeiro > Cadastros > Contas a Pagar)',
    'natureza-operacao': 'Acessou a tela de naturezas de operação (Financeiro > Cadastros > Naturezas de Operação)',
    conciliacao: 'Acessou a tela de conciliação bancária (Financeiro > Controle > Conciliação Bancária)',
    extrato: 'Acessou a tela de extrato bancário (Financeiro > Controle > Extrato)',
    remessas: 'Acessou a tela de remessas bancárias (Financeiro > Controle > Remessas)',
    retornos: 'Acessou a tela de retornos bancários (Financeiro > Controle > Retornos)',
    historico: 'Acessou a tela de histórico de movimentações (Financeiro > Controle > Histórico Movimentações)',
  },
  relatorios: {
    'fluxo-diario': 'Acessou relatório de fluxo diário (Relatórios > Financeiro > Fluxo Diário)',
    'fluxo-mensal': 'Acessou relatório de fluxo mensal (Relatórios > Financeiro > Fluxo Mensal)',
    'analise-recebimentos': 'Acessou relatório de análise de recebimentos (Relatórios > Financeiro > Análise Recebimentos)',
    'analise-pagamentos': 'Acessou relatório de análise de pagamentos (Relatórios > Financeiro > Análise Pagamentos)',
    'posicao-contas': 'Acessou relatório de posição de contas (Relatórios > Financeiro > Posição Contas)',
    dre: 'Acessou relatório de dre gerencial (Relatórios > Financeiro > DRE)',
    'vendas-cliente': 'Acessou relatório de vendas por clientes (Relatórios > Vendas > Vendas Cliente)',
    'vendas-vendedor': 'Acessou relatório de vendas por vendedores (Relatórios > Vendas > Vendas Vendedor)',
    'comissao-vendedor': 'Acessou relatório de comissão por vendedores (Relatórios > Vendas > Comissão Vendedor)',
    'mais-vendidos': 'Acessou relatório de produtos mais vendidos (Relatórios > Vendas > Mais Vendidos)',
    'produtos-cliente': 'Acessou relatório de produtos por clientes (Relatórios > Geral > Produtos Cliente)',
    clientes: 'Acessou relatório de clientes (Relatórios > Geral > Clientes)',
    'giro-estoque': 'Acessou relatório de giro de estoque (Relatórios > Geral > Giro Estoque)',
    'dashboard-dre': 'Acessou relatório de dashboard DRE (Relatórios > Financeiro > Dashboard DRE)',
    'dashboard-resumo': 'Acessou relatório de dashboard (Relatórios > Financeiro > Dashboard)',
  },
  perfil: {
    0: 'Acessou a tela de perfil (Meus Dados)'
  },
  usuarios: {
    0: 'Acessou a tela de usuários (Meus Usuários)'
  },
  'dados-empresa': {
    0: 'Acessou a tela de dados da empresa (Dados da Empresa)'
  },
  'config-empresa': {
    0: 'Acessou a tela de configurações da empresa (Configurações da Empresa)'
  },
  'importa-dados': {
    0: 'Acessou a tela de importação de planilhas (Importar Dados)',
    produtos: 'Acessou a tela de importação de produtos por planilha (Importar Dados > Produtos)',
    clientes: 'Acessou a tela de importação de clientes por planilha (Importar Dados > Clientes)',
    fornecedores: 'Acessou a tela de importação de fornecedores por planilha (Importar Dados > Fornecedores)',
    vendedores: 'Acessou a tela de importação de vendedores por planilha (Importar Dados > Vendedores)',
    transportadoras: 'Acessou a tela de importação de transportadoras por planilha (Importar Dados > Transportadoras)',
    contas_pagar: 'Acessou a tela de importação de contas a pagar por planilha (Importar Dados > Contas a Pagar)',
    contas_receber: 'Acessou a tela de importação de contas a receber por planilha (Importar Dados > Contas a Receber)',
    gerenciar: 'Acessou a tela de gerenciamento de planilhas importadas (Importar Dados > Gerenciar)',
    xml: 'Acessou a tela de importação de cadastros a partir do XML (Importar Dados > Cadastros XML)',
  },
  'meu-plano': {
    0: 'Acessou a tela de meu plano (Meu Plano)'
  },
  'acesso-suporte': {
    0: 'Acessou a tela de acesso suporte (Icone Suporte)'
  },
  sugestoes: {
    0: 'Acessou a tela de sugestões (Icone Sugestões)'
  },
  tickets: {
    0: 'Acessou a tela de tickets (Icone Tickets)'
  },
  'meu-contador': {
    0: 'Acessou a tela de meu contador (Meu Contador)'
  },
  logs: {
    0: 'Acessou a tela de logs do sistema (Logs do Sistema)'
  }
}

export default map
